.displayHole {
    font-size :  2em;
    color :aliceblue;
    font-weight: bolder;
    font-style: italic;
    border: 3px;
    border-style: solid;
    border-color: grey;
    background-color: dimgrey;
}

.score-buttons {
    position : relative;
    display : block;
    margin: .5em;
    padding-top: 1em;
    height : 12.5em;
    background-color: aliceblue;
    font-style: italic;
}

.score-button-title {
    text-align: center;
    font-size : 2em;
    font-weight: bolder;
}

.score-button-strokes-taken {
    text-align : center;
    font-size : 4.5em;
    font-weight: bolder;
}

.score-button-icons {
    width : 50%;
    font-size: 2.5em;
    padding-top: .25em;
}

.score-up {
    text-align : right;
}

.score-down {
    text-align: left;
}

.score-card {
    position : relative;
    display : block;
    padding-top: .5em;
    height : 1.5em;
    background-color: aliceblue;
    border: 3px;
    border-style: solid;
    border-color: grey;
    font-size: 1.5em;
    font-style : italic;
    font-weight: bolder;
}

.score-card-heading {
    padding-top: 0em;
    background-color: dimgrey;
    color :aliceblue;
    font-size: 1em;
    height : 3em;
    text-align:center;
}

.score-total {
    margin: .5em;
    font-size: 2.5em;

}
.score-total-heading {
    padding-top: 0em;
    background-color: dimgrey;
    color :aliceblue;
    font-size: 1.5em;
    height : 1.5em;
    text-align:center;
}

.hole {
    margin : .5em;
}

.score {
    margin : .5em 0em;
    margin-right: .5em;
}

.column-score {
    width : 50%;
}