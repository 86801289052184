.App {
  text-align: center;
  margin-top: 1%;
}

.App-logo {

  width : 100%
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hide {
  display : none;
}

.column {
  float : left;
}

.left {
  width : 25%;
}

.right {
  width: 35%;
}

.middle {
  width: 40%;
  background-color: gray;
}

.row::after {
  content: "";
  display : table;
  clear : both

}

button {
  background-color :dimgrey;
  color : aliceblue;
  height : 3em;
  border: 3px;
  border-style: solid;
  border-color: black;
  font-weight: bolder;
}

.buttonNavPrev {
  float : left;
  margin-left: .5em;
}

.buttonNavNext {
  float : right;
  margin-right: .5em;
}